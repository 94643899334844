<template>
  <div class="container contact-container m-b-110">
    <div class="card p-1 text-first border-0">
      <div class="card-body">
        <div class="row update-btn-div">
          <h2 class="mt-0 mt-sm-4 mb-4 fw-bold font-30">{{ $t('contact.default.pageTitle') }}</h2>
          <div class="col">
            <p v-html="$t('contact.default.detailContent')"></p>
            <!--<p class="mt-4 number"><i class="fas fa-phone"></i> <span>０３−００００−００００</span></p>-->
            <span><a href="https://jta-tennis.atlassian.net/servicedesk/customer/portal/2" target="_blank" class="color-blue">https://jta-tennis.atlassian.net/servicedesk/customer/portal/2</a></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "Contact",
        mounted() {
          window.scrollTo({top: 0, behavior: "auto"});
        }
    }
</script>

<style scoped>
.contact-container {
  padding-left: 0;
  padding-right: 0;
  color: #333333;
}

.update-btn-div {
  text-align: left;
  margin-bottom: 20px;
}

.number {
  font-family: "SF Pro";
  color: #2663D1;
  font-weight: bold;
  font-size: 30px;
}

.fa-phone {
  transform: rotate(94deg);
}

@media only screen and (max-width: 768px) {

  .card {
    border-bottom: none;
  }

  .card-body {
    padding-left: 30px;
  }

  .update-btn-div {
    text-align: left;
  }

  .update-btn-div h2 {
    font-size: 30px;
  }

  .update-btn-div > p {
    font-size: 16px;
  }

  .number {
    font-size: 30px;
  }

}

@media only screen and (max-width: 576px) {

  .update-btn-div h2 {
    font-size: 24px;
  }

  .update-btn-div > p {
    font-size: 13px;
  }

  .number {
    font-size: 20px;
  }

  .contact-container {
    margin-top: -40px;
    margin-left: -10px;
  }
}

</style>